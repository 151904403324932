/***************
 *  WsModal
 */

import React, { Component } from 'react'
//import { Image, KeyboardAvoidingView, Modal, TextStyle, ViewStyle } from "react-native";
import {
  Image,
  KeyboardAvoidingView,
  Modal,
  TextStyle,
  ViewStyle,
} from './react-components'
import { rzlog } from './inc'
import { RzBtn } from './rzbtn'
import { toStyles } from './rzstyles'
import { RzTxt } from './rztxt'
import { RzCol, RzRow, RzView } from './rzview'

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPopover,
  CRow,
  CTooltip,
} from '@coreui/react-pro'

/*********** */
const rzIs = rzlog.makeDefs()

/*********** */

const st_pophead_txt = toStyles(`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;

text-align: center;

color: #333333;`)
/************* */

export interface RzModalProp {
  visible?: boolean
  children: React.ReactNode
  style?: ViewStyle

  headStyle?: ViewStyle
  bodyStyle?: ViewStyle

  type?: string
  titleChildren?: React.ReactNode

  title?: string
  titleTextStyle?: TextStyle
  titleStyle?: ViewStyle

  prevOn?: boolean
  prevIcon?: Image
  prevEl?: React.ReactNode
  prevStyle?: ViewStyle

  nextOn?: boolean
  nextIcon?: Image
  nextEl?: React.ReactNode
  nextStyle?: ViewStyle

  cStyle?:React.CSSProperties
  size?: 'sm' | 'lg' | 'xl'
  onClose?: () => void  
}

interface RzModalStat {
}

export class RzModal extends Component<RzModalProp, RzModalStat> {
  constructor(pr?: any) {
    super(pr)
  }

  componentDidMount(): void {}

  public render() {
    let hasHead =
      Boolean(this.props.type) ||
      Boolean(this.props.title) ||
      Boolean(this.props.prevIcon) ||
      Boolean(this.props.nextIcon) ||
      Boolean(this.props.prevEl) ||
      Boolean(this.props.nextEl)

    let visible = this.props.visible;
    let size = this.props.size || 'sm';

     // <CModal visible={visible} onClose={this.props.onClose} style={this.props.cStyle} size={size} backdrop={'static'}>
    return (
      <CModal visible={visible} onClose={this.props.onClose} style={{...this.props.cStyle, ... (visible? {display:"flex"}:{})}} size={size} backdrop={'static'}>
        {this.props.type === 'review' && <CModalHeader>{this.props.titleChildren}</CModalHeader>}
        {this.props.type === 'ProfileFromSettle' && <CModalHeader style={{'fontWeight':'700','fontSize':'24px','color':'#444444',width:'1380px', position:'fixed' ,borderBottom:'none',zIndex:99,backgroundColor:'white'}}>{this.props.title}</CModalHeader>}
        {this.props.type !== 'review' && this.props.type !== 'ProfileFromSettle' && <CModalHeader style={{'fontWeight':'700','fontSize':'24px','color':'#444444'}}>{this.props.title}</CModalHeader> }
        <CModalBody>{this.props.children}</CModalBody>
      </CModal>
      // <Modal animationType="fade" transparent={true} visible={visible}>
      //   <KeyboardAvoidingView style={{ height: '100%' }} behavior={'padding'}>
      //     <RzView
      //       style={{
      //         flex: 1,
      //         width: '100%',
      //         borderWidth: 0,
      //         flexDirection: 'row',
      //         backgroundColor: '#44444444',
      //         justifyContent: 'flex-end',
      //         alignItems: 'flex-end',
      //       }}
      //     >
      //       <RzView
      //         style={{
      //           flex: 1,
      //           width: '100%',
      //           height: 160,
      //           paddingTop: 10,
      //           borderRadius: 10,
      //           borderColor: '#cccccc',
      //           borderWidth: 0,
      //           backgroundColor: '#ffffff',
      //           padding: 5,
      //           ...this.props.style,
      //         }}
      //       >
      //         {hasHead && this.doRenderHead()}
      //         <RzView
      //           style={{
      //             flex: 1,
      //             flexDirection: 'column',
      //             ...this.props.bodyStyle,
      //           }}
      //         >
      //           {this.props.children}
      //         </RzView>
      //       </RzView>
      //     </RzView>
      //   </KeyboardAvoidingView>
      // </Modal>
    )
  }

  doRenderHead() {
    let leftOn =
      Boolean(this.props.prevEl) ||
      Boolean(this.props.prevIcon) ||
      this.props.prevOn !== undefined
    let rightOn =
      Boolean(this.props.nextEl) ||
      Boolean(this.props.nextIcon) ||
      this.props.nextOn !== undefined

    let leftEl = Boolean(this.props.prevIcon) ? (
      <RzBtn icon={this.props.prevIcon} />
    ) : (
      <RzBtn text={'<'} />
    )

    let rightEl = Boolean(this.props.nextIcon) ? (
      <RzBtn
        icon={this.props.nextIcon}
        onPress={() => {
          this.doClose()
        }}
      />
    ) : (
      <RzBtn
        text={'>'}
        onPress={() => {
          this.doClose()
        }}
      />
    )

    if (rzIs.d)
      rzlog.debug('==================== leftOn=', leftOn, ',rightOn=', rightOn)
    return (
      <RzRow
        style={{
          width: '100%',
          borderWidth: 0,
          justifyContent: 'space-between',
          ...this.props.headStyle,
        }}
      >
        {leftOn &&
          (this.props.prevEl || (
            <RzCol style={{ borderWidth: 1, ...this.props.prevStyle }}>
              {leftEl}
            </RzCol>
          ))}
        {!leftOn && (
          <RzCol>
            <RzTxt text=" " />
          </RzCol>
        )}
        <RzCol
          style={{
            justifyContent: 'center',
            borderWidth: 0,
            borderColor: 'red',
            ...this.props.titleStyle,
          }}
        >
          <RzTxt
            textStyle={{ ...st_pophead_txt, ...this.props.titleTextStyle }}
            text={this.props.title}
          />
        </RzCol>
        {rightOn &&
          (this.props.nextEl || (
            <RzCol style={{ borderWidth: 0, ...this.props.nextStyle }}>
              {rightEl}
            </RzCol>
          ))}
        {!rightOn && (
          <RzCol>
            <RzTxt text=" " />
          </RzCol>
        )}
      </RzRow>
    )
  }

  doClose() {
    if (this.props.onClose) this.props.onClose()
  }
} //class
